<template>
  <div class="box">
      <div class="top-box">
          <div>
              <p>Hi，{{userInfo.name}}</p>
              <div class="riqi">
                  <img style="width:18px;height:18px;" src="https://middle-ground.shulanhealth.com/rili-icon%402x.png" alt="">
                  <span>今日 {{toDay}}</span>
              </div>
          </div>
          <img class="head-sty" :src="userInfo.headimgurl" alt="">
      </div>
      <div style="z-index:9px;" v-if="userInfo.agree == '2'">
          <div class="sp-box1" :style="'box-sizing: border-box;'">
              <div style="font-size:16px;text-align: center;">
                  《免责声明》
              </div>
              <div style="font-size:14px;text-align: center;margin-top: 10px;">
                  请滑动到底部，并选择是否接受本声明
              </div>
              <p>SayHi拼车服务为公众提供方便、快捷、免费的拼车信息，方便于公众，服务于大众。任何使用本平台的用户均应仔细阅读本声明，用户可选择不使用本平台，使用本平台的行为将被视为对本声明全部内容的认可。</p>
              <p>1、本平台用户需为年满 18 周岁的具有完全民事行为能力成年人，未成年人用户需由其监护人许可并全程陪同，使用本平台拼车信息的视为已满 18 周岁的完全民事行为能力人，</p>
              <p>2、本平台为公益平台，平台内各项拼车信息均为网友自行发布，网上信息有风险，在实际拼车过程中，请您务必保持警惕!本平台概不负任何责任。</p>
              <p>3、用户拼车采取自原参加、风险自担、责任自负、费用自理的原则，本平台没有义务也无法做到对参与者的信息及行为进行审查，本平台提供的任何拼车信息不构成法律上的义务，更不构成分担用户任何损失或风险的依据;</p>
              <p>4、为保障车主和乘客双方权益，可以要求对方出示相关身份证明，并在启程前双方协商好各项事宜。在拼车的过程中，无论是开车人还是搭车人，均应注意自身的人身安全。在同意拼车前，各自需要对另一方的情况进行调查，判断，本平台不对任何人提供任何形式的安全担保，一旦发生侵犯人身、财产安全的事件，本平台不承担任责任;</p>
              <p>5、拼车过程中存在很多不可预见的危险，道路行驶、自身身体健康、自然灾害等因素均有可能造成对用户生命财产的伤害和损失。用户应当积极主动的购买保险，降低损失，一日发生事故和人身伤害，由保险公司及事故责任方负责赔偿，本平台不承担任何责任;</p>
              <p>6、本平台用户理解，本平台有权根据法律法规、司法或行政命令以及本平台内部政策情况对平台信息及本声明书进行调整、中止或终止，如因本平台进行调整、中止或终止的，所造成的用户损失本平台不承担任何责任;</p>
              <p>7、本平台保留下述权利:随时修改、删除在本平台发布的任何信息;随时停止本网站提供的服务;公安司法部门在对本平台上出现的不良信息进行调查时，向相关部门提供信息发布者的 IP 地址以及其他信息。</p>
              <p style="text-align:right;">SayHi拼车 2022年11月22日</p>
              <div class="bom-btn">
                <div class="box-item" style="height:25px;">
                    <van-checkbox v-model="iAgree" shape="square" icon-size="16px" style="font-size:12px;">请您认真阅读并充分理解上述信息,同意请勾选</van-checkbox>
                </div>
                <div class="submit-sty1" style="z-index:999;"  @click="submitNext" v-if="iAgree">下一步</div>
                <div class="submit-sty1" style="z-index:999;background: #f2f2f2;" v-else>下一步</div>
              </div>
          </div>
      </div>
      <div style="z-index:9px;" v-else>
          <div class="sp-box1" :style="'box-sizing: border-box;'">
              <div style="font-size:16px;">
                  请完善相关信息<span style="color:#E46E71;">*</span>
              </div>
              <div style="font-size:12px;color:#E46E71;margin-top: 6px;">( 提醒：以下信息除身份类型，其他数据本平台严格保密。*为必填项，车主信息请务必填写完整，以免审核失败)</div>
              <div style="font-size:14px;margin-top:15px;">
                  身份类型<span style="color:#E46E71;">*</span>
              </div>
              <div style="margin-top:10px;">
                <van-radio-group v-model="types" direction="horizontal">
                  <van-radio name="2">我是车主</van-radio>
                  <van-radio name="1">我是乘客</van-radio>
                </van-radio-group>
              </div>
              <div style="font-size:14px;margin-top:15px;" v-if="types == 2">
                  真实姓名<span style="color:#E46E71;">*</span>
              </div>
              <div style="margin-top:10px;" v-if="types == 2">
                <input style="border:1px solid #E0E0E0;width:100%;height:40px;border-radius: 8px;padding: 0 10px;box-sizing: border-box;" type="text" placeholder="请输入真实姓名" maxlength="11" v-model="real_name">
              </div>
              <div style="font-size:14px;margin-top:15px;" v-if="types == 2">
                  车牌号<span style="color:#E46E71;">*</span>
              </div>
              <div style="margin-top:10px;" v-if="types == 2">
                <div style="border:1px solid #E0E0E0;width:100%;height:40px;line-height: 40px;; border-radius: 8px;padding: 0 10px;box-sizing: border-box;" type="text" @click="showGs" :style="car_no == '' ? 'color:#757575;' : ''">{{car_no == '' ? '请输入车牌号' : car_no}}</div>
              </div>
              <div style="font-size:14px;margin-top:15px;">
                  手机号<span style="color:#E46E71;">*</span>
              </div>
              <div style="margin-top:10px;">
                <input style="border:1px solid #E0E0E0;width:100%;height:40px;border-radius: 8px;padding: 0 10px;box-sizing: border-box;" type="text" placeholder="请输入手机号" maxlength="11" v-model="mobile">
              </div>
              <div style="font-size:14px;margin-top:15px;" v-if="types == 2">
                  身份证号<span style="color:#E46E71;">*</span>
              </div>
              <div style="margin-top:10px;" v-if="types == 2">
                <input style="border:1px solid #E0E0E0;width:100%;height:40px;border-radius: 8px;padding: 0 10px;box-sizing: border-box;" type="text" placeholder="请输入身份证号" v-model="id_card">
              </div>
              <div class="box-item3" v-if="types == 2">
                    <div style="margin-bottom:10px;">上传车辆行驶证<span style="color: red">*</span></div>
                    <div>
                        <div
                            class="x-box1"
                            style="display: flex; flex-wrap: wrap; width: 100%"
                        >
                            <div
                                style="
                                    display: flex;
                                    align-items: center;
                                    flex-wrap: wrap;
                                    width: 100%;
                                "
                            >
                                <div
                                    class="img-list"
                                    v-for="(item, index) in jyjcbgList"
                                    :key="index"
                                >
                                    <img
                                        style="
                                            width: 100%;
                                            height: 77px;
                                            border-radius: 10px;
                                        "
                                        :src="item"
                                        @click="showPic(index)"
                                    />
                                    <img
                                        src="https://static.shulanhealth.com/del-pic-icon.png"
                                        class="dele"
                                        @click="delPic(index)"
                                    />
                                </div>
                                <div
                                    class="add-img1"
                                    v-if="jyjcbgList.length < 8"
                                >
                                    <a
                                        href="javascript:;"
                                        class="file"
                                        style="
                                            height: 77px;
                                            cursor: pointer;
                                            border-radius: 10px;
                                        "
                                    >
                                        <div style="text-align: center">
                                            <img
                                                class="img-size1"
                                                src="http://middle-ground.shulanhealth.com/add-img.png"
                                            />
                                        </div>
                                        <input
                                            style="height: 77px"
                                            type="file"
                                            name=""
                                            accept="image/*"
                                            id=""
                                            @change="upLoadImg($event)"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
              <div class="box-item3" v-if="types == 2">
                    <div style="margin-bottom:10px;">上传驾驶证<span style="color: red">*</span></div>
                    <div>
                        <div
                            class="x-box1"
                            style="display: flex; flex-wrap: wrap; width: 100%"
                        >
                            <div
                                style="
                                    display: flex;
                                    align-items: center;
                                    flex-wrap: wrap;
                                    width: 100%;
                                "
                            >
                                <div
                                    class="img-list"
                                    v-for="(item, index) in jyjcbgList1"
                                    :key="index"
                                >
                                    <img
                                        style="
                                            width: 100%;
                                            height: 77px;
                                            border-radius: 10px;
                                        "
                                        :src="item"
                                        @click="showPic1(index)"
                                    />
                                    <img
                                        src="https://static.shulanhealth.com/del-pic-icon.png"
                                        class="dele"
                                        @click="delPic1(index)"
                                    />
                                </div>
                                <div
                                    class="add-img1"
                                    v-if="jyjcbgList1.length < 8"
                                >
                                    <a
                                        href="javascript:;"
                                        class="file"
                                        style="
                                            height: 77px;
                                            cursor: pointer;
                                            border-radius: 10px;
                                        "
                                    >
                                        <div style="text-align: center">
                                            <img
                                                class="img-size1"
                                                src="http://middle-ground.shulanhealth.com/add-img.png"
                                            />
                                        </div>
                                        <input
                                            style="height: 77px"
                                            type="file"
                                            name=""
                                            accept="image/*"
                                            id=""
                                            @change="upLoadImg1($event)"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
              <div style="font-size:14px;margin-top:15px;">
                  您在几区？<span style="color:#E46E71;"></span>
              </div>
              <div style="margin-top:10px;">
                <van-radio-group v-model="region" direction="horizontal">
                  <van-radio name="一区">一区</van-radio>
                  <van-radio name="二区">二区</van-radio>
                  <van-radio name="三区">三区</van-radio>
                  <van-radio name="四区">四区</van-radio>
                  <!-- <van-radio name="2">五区</van-radio> -->
                </van-radio-group>
              </div>
              <div style="font-size:14px;margin-top:15px;">
                  您的楼栋/单元/门户号？
              </div>
              <div style="margin-top:10px;display: flex;align-items: center;">
                <input  style="border:1px solid #E0E0E0;width:80px;height:40px;border-radius: 8px;padding: 0 10px;box-sizing: border-box;" type="number" placeholder="楼栋号" v-model="homes1"><span style="font-size:14px;color:#757575;">&nbsp;幢&nbsp;</span>
                <input :disabled="homes1== ''? true: false" style="border:1px solid #E0E0E0;width:80px;height:40px;border-radius: 8px;padding: 0 10px;box-sizing: border-box;" type="number" placeholder="单元号" v-model="homes2"><span style="font-size:14px;color:#757575;">&nbsp;单元&nbsp;</span>
                <input :disabled="homes2== ''? true: false" style="border:1px solid #E0E0E0;width:80px;height:40px;border-radius: 8px;padding: 0 10px;box-sizing: border-box;" type="number" placeholder="门户号" v-model="homes3">
              </div>
              <div class="submit-sty1" style="z-index:999;"  @click="submitSetUserInfo">提交</div>
          </div>
      </div>
      <van-popup v-model="showDepartmentPopup" round position="bottom">
            <div style="text-align:center;margin-top:20px;font-size: 18px;">{{cph}}</div>
            <div style="width: 94%;margin-left:3%;position: relative;display: flex;flex-wrap: wrap;align-items: center;justify-content: center;margin-top: 20px;margin-bottom: 25px;;">
                <div v-for="item in gs" :key="item" class="btngs" @click="selGs(item)">{{item}}</div>              
            </div>
            <div style="width: 94%;margin-left:3%;position: relative;display: flex;flex-wrap: wrap;align-items: center;justify-content: center;margin-top: 20px;margin-bottom: 25px;;">
                <div v-for="item in shuzi" :key="item" class="btngs" @click="selGs(item)">{{item}}</div>              
            </div>
            <div style="width: 94%;margin-left:3%;position: relative;display: flex;flex-wrap: wrap;align-items: center;justify-content: center;margin-top: 20px;margin-bottom: 25px;;">
                <div v-for="item in zimu" :key="item" class="btngs" @click="selGs(item)">{{item}}</div>
                <div class="btngs" style="width:80px;" @click="delCarno">删除</div>         
            </div>
            <div class="submit-sty1" style="width:90%;margin-left:5%;margin-bottom:20px;" @click="sureCarNo">确定</div>
      </van-popup>
      <loading :show="isLoading" :msg="'上传中...'"></loading>
  </div>
</template>
<script>
import { RadioGroup, Radio, Toast, Dialog, Popup,ImagePreview } from "vant";
import dayjs from 'dayjs'
import Bus from "@/utils/bus";
import Config from "@/config";
import loading from "./comm/loading";
import * as imageConversion from 'image-conversion'
import { passenger } from "@/api";
export default {
  components: {
    loading,
      VanRadioGroup: RadioGroup,
      VanRadio: Radio,
      VanPopup: Popup
      // VanPicker: Picker,
  },
  data() {
      return {
          isLoading:false,
          iAgree: false,
          isAuth: false,
          showDepartmentPopup: false,
          pmHeight: 0,
          toDay: "",
          gs: ['京','津','沪','渝','蒙','新','藏','宁','桂','港','澳','黑','吉','辽','晋','冀','青','鲁','豫','苏','皖','浙','闽','赣','湘','鄂','粤','琼','甘','陕','贵','云','川'],
          shuzi: [1,2,3,4,5,6,7,8,9,0],
          zimu: ['Q','W','E','R','T','Y','U','I','O','P','A','S','D','F','G','H','J','K','L','Z','X','C','V','B','N','M'],
          columns: [
              {
                  text: '第一病区',
                  value: '第一病区'
              }
          ],
          departmentInfo: null,
          userInfo: null,
          types: "",
          real_name: "",
          id_card: "",
          jszpic: "",
          xszpic: "",
          car_no: "",
          cph: "",
          mobile: "",
          region: "",
          homes: "",
          homes1: "",
          homes2: "",
          homes3: "",
          jyjcbgList: [],
          jyjcbgList1: [],
          jyjcbgStringList:[],
          jyjcbgStringList1:[]
      }
  },
  filters: {
      formatDateTime(date) {
          return date !== undefined && date !== null
              ? date.substring(0, 16)
              : "";
      },
  },
  mounted() {
      document.title = "完善身份信息";
      
      Bus.$on('my_events', this.handleEvents)
      this.pmHeight =(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 109;
      this.toDay = dayjs().format('YYYY年MM月DD日')
      this.getUserinfoAscy()
  },
  methods: {
    submitNext() {
        passenger.IAgree({}).then(res => {
            if(res.data.code ==200) {
                this.getUserinfoAscy()

            }
        })
    },
    isVehicleNumber(vehicleNumber) {
        var xreg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
        var creg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
        if(vehicleNumber.length == 7){
            return creg.test(vehicleNumber);
        } else if(vehicleNumber.length == 8){
            return xreg.test(vehicleNumber);
        } else{
            return false;
        }
    },
    sureCarNo() {
        console.log(this.isVehicleNumber(this.cph))
        // if(!this.isVehicleNumber(this.cph)) {
        //     Toast.fail('车牌号不正确');
        //     return
        // }
        this.car_no = this.cph
        this.showDepartmentPopup = false
    },
    delCarno() {
        this.cph = this.cph.slice(0,this.cph.length-1)
    },
    showGs() {
        this.showDepartmentPopup = true
    },
    selGs(e) {
        this.cph = this.cph + e
    },
    getUserinfoAscy() {
          let params = {};
          passenger.getMe(params)
              .then((res) => {
                  if(res.data.code == 200) {
                      // // Toast.success('设置成功')
                      this.userInfo = res.data.data
                      // this.isAuth = this.userInfo.dept_id == '' ? false : true
                      localStorage.setItem('user', JSON.stringify(res.data.data))
                      if(res.data.data.types !== null) {
                        if(res.data.data.agree == '1') {
                            window.location.href = '/zybrlb'
                        }
                      }
                  } else {
                      Toast.fail('获取信息失败')
                  }
              })
              .catch(() => {
                  Toast.fail('获取信息失败')
              });
      },
      submitSetUserInfo() {
        let h1 = this.homes1 == '' ? '' : this.homes1 + '幢'
        let h2 = this.homes2 == '' ? '' :  this.homes2+ '单元'
        let h3 = this.homes3 == '' ? '' : this.homes3 + ''
          if(this.types == '') {
            Toast.fail('请选择身份类型')
            return
          }
          if(this.types == 2) {
            if(this.real_name == '') {
              Toast.fail('请输入真实姓名')
              return
            }
            if(this.car_no == '') {
              Toast.fail('请输入车牌号')
              return
            }
            if(this.mobile == '') {
                Toast.fail('请输入手机号')
                return
            }
            if(this.id_card == '') {
              Toast.fail('请输入身份证号')
              return
            }
            if(this.jyjcbgStringList.length == 0) {
              Toast.fail('请上传驾驶证')
              return
            }
            if(this.jyjcbgStringList1.length == 0) {
              Toast.fail('请上传行驶证')
              return
            }
          }
          if(this.mobile == '') {
            Toast.fail('请输入手机号')
            return
          }
          let params = {
              types: this.types,
              car_no: this.car_no.toUpperCase(),
              mobile: this.mobile,
              region: this.region,
              homes: h1 + h2 + h3,
              id_card: this.id_card,
              real_name: this.real_name,
              jszpic: this.jyjcbgStringList.toString(),
              xszpic: this.jyjcbgStringList1.toString(),
          };
           passenger.setUserInfo(params)
               .then((res) => {
                   if(res.data.code == 200) {
                     if(this.types == 2) {
                      Dialog.alert({
                          title: '认证提醒',
                          message: '您的车主审核已提交，请耐心等待',
                      }).then(() => {
                      // on close
                          this.getUserinfoAscy()
                      });
                     } else {
                         Toast.success('设置成功')
                         this.getUserinfoAscy()
                     }
                    
                   } else {
                       Toast.fail('设置失败')
                   }
               })
               .catch(() => {
                   // Toast.fail('设置失败')
               });
          
      },
      handleEvents(e) {
          this.departmentInfo = JSON.parse(e)
      },
      selDepartment() {
          this.$router.push({name: 'search-department'})
      },
      fssqm() {
          this.$router.push({name: 'barcode'})
      },
      phsp() {
          Toast('正在开发中...')
          //this.$router.push({name: 'shengpi'})
      },
      fxrq() {
          Toast('正在开发中...')
          //this.$router.push({name: 'fxry'})
      },
      zybr() {
          this.$router.push({name: 'zybrlb'})
      },
      hlry() {
          this.$router.push({name: 'phrylb'})
      },
      upLoadImg(e) {
          let file = e.target.files[0];
          this.beforeRead(file)
      },
      upLoadImg1(e) {
          let file = e.target.files[0];
          this.beforeRead1(file)
      },
      uploadImg(fd) {
          // let params = {
          //     file: fd,
          //     biz: 'temp'
          // };
          
          passenger.upLoadFile(fd)
              .then((res) => {
                  this.jyjcbgList.push(
                      Config.resource.base + res.data.key
                  );
                  this.jyjcbgStringList.push(res.data.key);
                  this.isLoading = false;
              })
              .catch(() => {
                  this.isLoading = false;
              });
      },
      uploadImg1(fd) {
          // let params = {
          //     file: fd,
          //     biz: 'temp'
          // };
          
          passenger.upLoadFile(fd)
              .then((res) => {
                  this.jyjcbgList1.push(
                      Config.resource.base + res.data.key
                  );
                  this.jyjcbgStringList1.push(res.data.key);
                  this.isLoading = false;
              })
              .catch(() => {
                  this.isLoading = false;
              });
      },
      delPic(index) {
          this.jyjcbgList.splice(index, 1);
          this.jyjcbgStringList.splice(index, 1);
      },
      showPic(index) {
          ImagePreview(this.jyjcbgList, index);
      },
      delPic1(index) {
          this.jyjcbgList1.splice(index, 1);
          this.jyjcbgStringList1.splice(index, 1);
      },
      showPic1(index) {
          ImagePreview(this.jyjcbgList1, index);
      },
      beforeRead(file) {
        this.isLoading = true;
        // 上传之前校验
        return new Promise((resolve, reject) => {
          console.log(reject) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
          console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
          imageConversion.compressAccurately(file, 100).then(res => { // console.log(res)
            res = new File([res], file.name, { type: res.type, lastModified: Date.now() })
            console.log('压缩后', res)
            let fd = new FormData();
            fd.append("pic", res);
            this.uploadImg(fd);
            resolve(res)
          })
        })
      },
      beforeRead1(file) {
        this.isLoading = true;
        // 上传之前校验
        return new Promise((resolve, reject) => {
          console.log(reject) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
          console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
          imageConversion.compressAccurately(file, 100).then(res => { // console.log(res)
            res = new File([res], file.name, { type: res.type, lastModified: Date.now() })
            console.log('压缩后', res)
            let fd = new FormData();
            fd.append("pic", res);
            this.uploadImg1(fd);
            resolve(res)
          })
        })
      }
  }
};
</script>
<style scoped>
.box {width: 100%;background: linear-gradient(180deg, #00B5A0 0%, #0C9793 100%);min-height: 100vh;position: relative;overflow: hidden;}
.bg1 {position: absolute;top: 0;left: 0;width: 100%;z-index: 1;}
.bg2 {position: absolute;bottom: 0;left: 0;width: 100%;z-index: 1;}
.head-sty {width: 44px;height: 44px;border-radius: 50%;}
.top-box {width: 90%;margin-left: 5%;display: flex;align-items: center;justify-content: space-between;margin-top: 21px;z-index: 2;}
.top-box div p {font-size: 16px;color: #FAFAFA;line-height: 0;}
.riqi {display: flex;align-items: center;}
.riqi img {margin-right: 5px;}
.riqi span {font-size: 13px;color: #FAFAFA;}
.sp-box {width: 92%;margin-left: 4%;height: 88px;background: #FFFFFF;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;margin-top: 24px;display: flex;align-items: center;justify-content: space-between;}
.sp-box1 {width: 92%;margin-left: 4%;background: #FFFFFF;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;margin-top: 24px;padding: 13px 20px;}
.sp-box div {width: 50%;text-align: center;height: 66px;display: flex;flex-direction: column;align-items: center;justify-content: space-around;}
.sp-box div p:nth-child(1) {margin: 0;font-size: 13px;color: #333333;font-weight: 500;}
.sp-box div p:nth-child(2) {margin: 0;font-size: 24px;color: #919295;font-weight: 500;}
.active-box {width: 92%;margin-left: 4%;height: 108px;display: flex;align-items: center;justify-content: space-between;margin-top: 20px;}
.active-box > div {width: 31%;display: flex;flex-direction: column;align-items: center;justify-content: center;height: 108px;background: #FFFFFF;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;}
.active-box div span {margin-top: 14px;font-size: 13px;color: #333333;}
.bqyqjc-box {width: 100%;margin-top:15px;display: flex;
align-items: center;justify-content: space-between;}
.bqyqjc-box > div:nth-child(1) {width: 48%;background: #fff;height: 97px;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;display: flex;flex-direction: column;justify-content: space-between;}
.bqyqjc-box > div:nth-child(2) {width: 48%;background: #fff;height: 97px;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;display: flex;flex-direction: column;justify-content: space-between;}
.arrow {border: 3px solid #B2B2B2;border-width: 0px 1px 1px 0px;display: inline-block;padding: 3px;transform: rotate(45deg);-webkit-transform: rotate(45deg);}
.qxz {height: 50px;border-radius: 8px;border: 1px solid #E0E0E0;display: flex;align-items: center;justify-content: space-between;padding: 0 15px;margin-top: 20px;z-index: 9;}
.submit-sty {height: 50px;background: #E7E8EB;border-radius: 25px;text-align: center;line-height: 50px;font-size: 16px;color: #fff;margin-top: 21px;position: relative;}
.submit-sty1 {height: 50px;background: #00AC97;border-radius: 25px;text-align: center;line-height: 50px;font-size: 16px;color: #fff;margin-top: 21px;position: relative;}
.badges{position: absolute;top: 10px;right: 10px;}
.btngs {width: 35px;height: 40px;border: 1px solid #f1f1f1;text-align: center;line-height: 40px;}
.btngs:active {
    background: #f2f2f2;
}
.bom-btn {display: flex;flex-direction: column; width: 100%;}
.bom-btn
.box-item3 {
    /* height: 121px; */
    width: 100%;
    /* margin-left: 4%; */
    margin-top: 15px;
    display: flex;
    font-size: 14px;
    flex-direction: column;
    justify-content: center;
}

.x-box1 .img-list:nth-child(4) {
  margin-right: 0;
}

.x-box1 .img-list:nth-child(8) {
  margin-right: 0;
}
.img-list {
  position: relative;
  width: 23%;
  height: 77px;
  margin-bottom: 8px;
  margin-right: 2%;
}
.dele {
  width: 22px;
  height: 22px;
  position: absolute;
  top: -7px;
  right: -7px;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
}
.add-img1 {
  position: relative;
  width: 23%;
  display: flex;
  align-items: center;
  height: 77px;
  margin-bottom: 7px;
}

.file {
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #1e88c7;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
  width: 100%;
  height: 87px;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file input {
  width: 100%;
  height: 87px;
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
}

.file:hover {
  color: #004974;
  text-decoration: none;
}

.img-size1 {
  width: 20px;
  height: 20px;
}
</style>
